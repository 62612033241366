<template>
    <connect-layout>
        <v-container class="d-flex flex-column align-center pb-12">
            <div class="iframe">
                <zapier-app-directory
                app="voiceform"
                link-target="new-tab"
                theme="light"
                introcopy="hide"
                create-without-template="show"
                use-this-zap="show"
                ></zapier-app-directory>
            </div>
        </v-container>
    </connect-layout>
</template>

<script>
import ConnectLayout from '../../../../../layouts/ConnectLayout.vue'

export default {
    components: {
        ConnectLayout
    }
}
</script>

<style lang="scss" scoped>
.iframe{
    min-height: 650px; 
    width: 100%;
    max-width:800px; 
    margin: 16px;
    z-index: 1;
}
.left-side-image{
    position: fixed;
    left: 0;
}
.right-side-image{
    position: fixed;
    right: 0;
}
</style>